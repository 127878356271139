<template>
  <div>
    <leads-add></leads-add>
  </div>
</template>

<script>
import LeadsAdd from './LeadsAdd.vue';

export default {
  components: {
    LeadsAdd,
  },

  data() {
    return {};
  },
};
</script>
